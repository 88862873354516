import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "./index.modules.css";

import { AuthContext } from "../../contexts/auth";
import { MdLogout } from "react-icons/md";
import { FaStore, FaUsers } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import {RiCoupon3Fill} from "react-icons/ri";

export const TopMenu = () => {
	const navigate = useNavigate();
	const { logout } = useContext(AuthContext);
	const location = useLocation();

	const handleLogout = () => {
		logout();
	};

	const isActiveRoute = (path) => {
		return location.pathname === path;
	};

	return (
		<header>
			<div
				className={`options-header ${
					isActiveRoute("/stores") ? "active" : ""
				}`}
				onClick={() => navigate("/stores")}
			>
				<FaStore className="icon" />
				<p>Lojas</p>
			</div>
			<div
				className={`options-header ${
					isActiveRoute("/users") ? "active" : ""
				}`}
				onClick={() => navigate("/users")}
			>
				<FaUsers className="icon" />
				<p>Usuários</p>
			</div>
			<div
				className={`options-header ${
					isActiveRoute("/vouchers") ? "active" : ""
				}`}
				onClick={() => navigate("/vouchers")}
			>
				<RiCoupon3Fill className="icon" />
				<p>Cupons</p>
			</div>
			<div
				className={`options-header ${
					isActiveRoute("/ranking") ? "active" : ""
				}`}
				onClick={() => navigate("/ranking")}
			>
				<BsGraphUpArrow className="icon" />
				<p>Ranking</p>
			</div>
			<div
				className="options-header"
				onClick={() => handleLogout()}
			>
				<MdLogout className="icon" />
				<p>Sair</p>
			</div>
		</header>
	);
};
