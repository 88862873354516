import { AxiosWithAuthToken } from "../config";

export const getEmployees = async (token) => {
	const employees = AxiosWithAuthToken(token)
		.get(`/users`)
		.then((response) => response.data)
		.catch((err) =>
			console.log("Falha ao obter dados dos colaboradores: ", err)
		);

	return employees;
};

export const getEmployee = async (token, id) => {
	const employee = await AxiosWithAuthToken(token)
		.get(`/users/${id}`)
		.then((response) => response.data)
		.catch((err) => {
			console.log("Falha ao obter dados do colaborador: ", err);
			throw err;
		});

	return employee;
};

export const patchPhoneID = async (token, idUser) => {
	try {
		const response = await AxiosWithAuthToken(token).patch(
			`/users/${idUser}/resetPhone`
		);

		return response.data;
	} catch (err) {
		throw err;
	}
};

export const patchPassword = async (token, idUser, newPassword) => {
	try {
		const response = await AxiosWithAuthToken(token).patch(
			`/users/${idUser}/resetPassword`,
			{
				newPassword,
			}
		);

		return response.data;
	} catch (err) {
		throw err;
	}
};

export const patchPersonalInfo = async (
	token,
	idUser,
	newIdStore,
	newName,
	newSurname,
	newTelephone,
	newImage
) => {
	try {
		const response = await AxiosWithAuthToken(token).patch(
			`/users/${idUser}/changePersonalInfo`,
			{
				newIdStore,
				newName,
				newSurname,
				newTelephone,
				newImage
			}
		);

		return response.status;
	} catch (err) {
		throw err;
	}
};

export const patchEmail = async (token, idUser, newEmail) => {
	try {
		const response = await AxiosWithAuthToken(token).patch(
			`/users/${idUser}/changeEmail`,
			{ newEmail }
		);
		return response;
	} catch (err) {
		throw err;
	}
};

export const postEmployee = async (
	token,
	idStore,
	name,
	surname,
	email,
	phone,
	role,
	password,
	admin,
	photo
) => {
	const response = await AxiosWithAuthToken(token)
		.post(`/users`, {
			idStore,
			name,
			surname,
			email,
			phone,
			role,
			password,
			admin,
			photo,
		})
		.then((response) => response)
		.catch((err) => console.log("Falha ao salvar o usuário", err));

	return response;
};

export const deleteEmployee = async (token, id) => {
	const responseData = await AxiosWithAuthToken(token)
		.delete(`/users/${id}`)
		.then((response) => response.status)
		.catch((err) => err);

	return responseData;
};
