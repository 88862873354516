import React from "react";

import './index.modules.css';

import { MdVerified } from "react-icons/md";
import {useNavigate} from "react-router-dom";

export const CardVoucher = (props) => {
	const navigate = useNavigate();

	const handleRedirectToVoucherPage = (id) => {
		navigate(`/voucher/${id}`);
	}

	return (
		<div className="voucherCardInfo" id={props.status ? "":"voucherInactive"} onClick={() => handleRedirectToVoucherPage(props.id)}>
			<div className="voucherImageArea">
				<img src={props.logoPath} alt="" />
			</div>
			<div className="voucherMoreInfo">
				<div className="voucherMainDetais">
					{props.featured ? <MdVerified className="iconFeatured"/> : null}
					<h5>{props.storeName}</h5>
					<p>Descrição Resumida: {props.description}</p>
					<p>Descrição Completa: {props.fullDescription}</p>
				</div>
				<div className="voucherDetails">
					<p>Validade: {props.expirationDate}</p>
					{
						props.featured ? (<p>Em Destaque</p>) : null
					}
					<p>{props.status ? "Ativo" : "Inativo"}</p>
				</div>
			</div>
		</div>
	);
};
