import React, { useContext, useEffect, useState } from "react";
import "./index.modules.css";
import { TopMenu } from "../../components/TopMenu";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { getVoucherRanking } from "../../api/Ranking/ranking";
import { AuthContext } from "../../contexts/auth";

export const Ranking = () => {
	const [total, setTotal] = useState(0);
	const [ranking, setRanking] = useState([]);
	const [loading, setLoading] = useState(false);

	const { token } = useContext(AuthContext);

	useEffect(() => {
		setLoading(true);

		async function getData() {
			try {
				const { statusCode, rankingList, total } =
					await getVoucherRanking(token);

				if (statusCode === 200) {
					setRanking(rankingList);
					setTotal(total);
					// console.log(rankingList);
					// console.log(total);
				}
			} catch (err) {}
		}
		getData();

		setLoading(false);
		//eslint-disable-next-line
	}, []);

	return (
		<main className="ranking">
			<TopMenu />
			<h3>Ranking de cupons</h3>
			<div className="graphRanking">
				{ranking.map((r) => (
					<div className="storeRanking" key={r.storeName}>
						<div className="storeNameRanking">
							<p>{r.storeName}</p>
						</div>
						<div
							className="rankingBar"
							style={{
								width: `${
									(r.usedVouchersAmount /
										total) *
									100
								}%`,
							}}
						>
							<p>{r.usedVouchersAmount}</p>
						</div>
					</div>
				))}
			</div>
			{loading && <LoadingSpinner />}
		</main>
	);
};
