import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
	RiDeleteBin5Fill,
	RiFileEditFill,
	RiCoupon3Fill,
	RiAddBoxFill,
} from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { TiCancel } from "react-icons/ti";

import "./index.modules.css";
import defaultImage from "../../defaulImage/noImage.png";

import { TopMenu } from "../../components/TopMenu";
import {
	getStore,
	getStoreVouchers,
	getStoreEmployees,
	deleteStore,
	patchStore,
} from "../../api/Stores/stores";

import { NoVoucher } from "../../components/NoVoucher";
import { NoEmployees } from "../../components/NoEmployees";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { deleteFolder } from "../../firebase/deleteFolderImage";
import { postVoucher } from "../../api/Voucher/vouchers";

import { uploadImage } from "../../firebase/uploadImage";
import { AuthContext } from "../../contexts/auth";


export const Store = (props) => {
	const navigate = useNavigate();
	const params = useParams();
	const id = params.id;
	const { token } = useContext(AuthContext);
	
	const [storeData, setStoreData] = useState({});
	const [employeesData, setEmployeesData] = useState([]);
	const [voucherData, setVoucherData] = useState([]);
	const [isNewVoucher, setNewVoucher] = useState(false);
	
	const [newStoreName, setNewStoreName] = useState("");
	const [newCategory, setNewCategory] = useState("");
	const [newImage, setNewImage] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	
	const [loading, setLoading] = useState(false);
	
	const [formValues, setFormValues] = useState({
		description: "",
		fullDescription: "",
		expirationDate: "",
		status: true,
		featured: false,
	});

	
	const handleOptionVoucher = () => {
		const voucherElement = document.querySelector(".storeVouchers");
		const employeeElement = document.querySelector(".storeEmployees");

		voucherElement.classList.remove("noDisplay");
		employeeElement.classList.add("noDisplay");
	};
	useEffect(() => {
		setLoading(true);
		async function getData() {
			try {
				const {stautsCode, data} = await getStore(token, id);
				if(stautsCode === 200){
					setStoreData(data);
					const vouchers = await getStoreVouchers(token, id);
					const employees = await getStoreEmployees(token, id);
					setVoucherData(vouchers);
					setEmployeesData(employees);
				}
			} catch (err) {
				console.log(err);
			}
		}
		getData();
		setLoading(false);
		//eslint-disable-next-line
	}, [id]);

	const handleOptionEmployee = () => {
		const voucherElement = document.querySelector(".storeVouchers");
		const employeeElement = document.querySelector(".storeEmployees");

		voucherElement.classList.add("noDisplay");
		employeeElement.classList.remove("noDisplay");
	};

	const handleDeleteStore = async () => {
		handleDeleteModalConfimation();
		setLoading(true);

		try {
			await deleteStore(token, id);
			await deleteFolder(`stores/${storeData.storeName}`);
			navigate("/stores");
		} catch (error) {
			console.log("Erro ao excluir a loja:", error);
		} finally {
			setLoading(false);
		}
	};

	const handleModal = () => {
		setNewVoucher(!isNewVoucher);
	};

	const isEditingModal = () => {
		setIsEditing(!isEditing);
	};

	const handleConfirmUpdate = async (event) => {
		event.preventDefault();
		setLoading(true);
		let newImageURL = null;

		try {
			if (!newStoreName && !newCategory && !newImage) {
				setLoading(false);
				isEditingModal();
				return;
			}

			if (newStoreName && newImage) {
				await deleteFolder(`stores/${storeData.storeName}`);
				newImageURL = await uploadImage(
					newImage,
					newImage.name,
					"stores",
					newStoreName
				);
			} else if (newStoreName) {
				await deleteFolder(`stores/${storeData.storeName}`);
				newImageURL = await uploadImage(
					storeData.logoPath,
					`${storeData.storeName}.jpeg`,
					"stores",
					newStoreName
				);
			} else if (newImage) {
				newImageURL = await uploadImage(
					newImage,
					newImage.name,
					"stores",
					storeData.storeName
				);
			}

			await patchStore(token, id, newStoreName, newCategory, newImageURL);
		} catch (err) {
			console.log(err);
		} finally {
			setNewStoreName("");
			setNewCategory("");
			setNewImage("");
			setLoading(false);
			isEditingModal();
			const store = await getStore(id);
			setStoreData(store[0]);
		}
	};

	const handleFormChange = (e) => {
		const { name, value, type, checked } = e.target;
		const newValue = type === "checkbox" ? checked : value;

		setFormValues((prevState) => ({
			...prevState,
			[name]: newValue,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const {
			description,
			fullDescription,
			expirationDate,
			status,
			featured,
		} = formValues;

		try {
			const response = await postVoucher(
				token,
				id,
				description,
				fullDescription,
				expirationDate,
				status,
				featured
			);

			if (response.stautsCode === 200) {
				// O cupom foi cadastrado com sucesso
				console.log(response.data);
			} else {
				// Houve um erro no cadastro do cupom
			}
		} catch (error) {
			console.log("Voucher não cadastrado", error);
		} finally {
			handleModal();
			const vouchers = await getStoreVouchers(token, id);
			setVoucherData(vouchers);
			setLoading(false);
		}
	};

	const handleDeleteModalConfimation = () => {
		const deleteModal = document.getElementById("deleteModal");
		deleteModal.classList.toggle("noDisplay");
	};

	const handleUserRedirect = (idUser) => {
		navigate(`/user/${idUser}`);
	};

	const handleVoucherRedirect = (idVoucher) => {
		navigate(`/voucher/${idVoucher}`);
	};

	return (
		<main id="storeDetails">
			<TopMenu />
			<section id="storeCard">
				{storeData.logoPath ? (
					<img src={storeData.logoPath} alt="" />
				) : (
					<img src={defaultImage} alt="Sem Imagem" />
				)}
				<div id="storeDescription">
					<h4>
						<span>Loja: </span>
						{storeData.storeName}
					</h4>
					<p>
						<span>Categoria: </span>
						{storeData.category}
					</p>
				</div>
				<div id="actionsButton">
					<div
						className="buttonZone"
						onClick={isEditingModal}
					>
						<button id="editButton">
							<RiFileEditFill className="iconAction" />
							Editar
						</button>
					</div>
					<div className="buttonZone">
						<button id="deleteButton">
							<RiDeleteBin5Fill
								className="iconAction"
								onClick={
									handleDeleteModalConfimation
								}
							/>
							Excluir
						</button>
					</div>
				</div>
			</section>
			<nav>
				<div id="switchMenu">
					<div
						className="switchMenuOption"
						onClick={() => handleOptionVoucher()}
					>
						<RiCoupon3Fill />
						<p>Cupons</p>
					</div>
					<div
						className="switchMenuOption"
						onClick={() => handleOptionEmployee()}
					>
						<FaUserAlt />
						<p>Colaboradores</p>
					</div>
				</div>
			</nav>
			{!!isNewVoucher && (
				<div className="addVoucherModalArea">
					<div className="addVoucherArea">
						<h2>Novo Cupom</h2>
						<form onSubmit={handleSubmit}>
							<div>
								<label htmlFor="description">
									Descrição Resumida:
								</label>
								<input
									type="text"
									id="description"
									name="description"
									value={
										formValues.description
									}
									onChange={
										handleFormChange
									}
								/>
							</div>
							<div>
								<label htmlFor="fullDescription">
									Descrição Completa:
								</label>
								<br />
								<textarea
									id="fullDescription"
									name="fullDescription"
									value={
										formValues.fullDescription
									}
									onChange={
										handleFormChange
									}
								></textarea>
							</div>
							<div>
								<label htmlFor="expirationDate">
									Validade:
								</label>
								<input
									type="date"
									id="expirationDate"
									name="expirationDate"
									value={
										formValues.expirationDate
									}
									onChange={
										handleFormChange
									}
								/>
							</div>
							<div className="modalCheckbox">
								<label htmlFor="status">
									Ativo?
								</label>
								<input
									type="checkbox"
									id="status"
									name="status"
									checked={
										formValues.status
									}
									onChange={
										handleFormChange
									}
								/>
								<label htmlFor="featured">
									Destaque?
								</label>
								<input
									type="checkbox"
									id="featured"
									name="featured"
									checked={
										formValues.featured
									}
									onChange={
										handleFormChange
									}
								/>
							</div>
							<div className="buttonContainer">
								<button
									className="cancelButton"
									onClick={handleModal}
								>
									Cancelar
								</button>
								<button
									className="submitButton"
									type="submit"
								>
									Cadastrar
								</button>
							</div>
						</form>
					</div>
				</div>
			)}
			<section className="voucherUsers">
				<div className="storeVouchers">
					<div id="vouchersFiltersAndActions">
						<button
							className="addVoucherButton"
							onClick={handleModal}
						>
							<RiAddBoxFill />
							<span>Novo Cupom</span>
						</button>
					</div>
					{voucherData.map((voucher) =>
						voucher.id ? (
							<div
								className="voucherContainer"
								id={
									voucher.status
										? ""
										: "inactiveVoucher"
								}
								key={voucher.id}
								onClick={() =>
									handleVoucherRedirect(
										voucher.id
									)
								}
							>
								<div id="basicInfo">
									{voucher.featured ? (
										<MdVerified
											className="activeVoucher"
											id="activeVoucher"
										/>
									) : null}
									<p
										hidden={true}
										className="voucherID"
									>
										{voucher.id}
									</p>
									<p>
										<span>
											Descrição
											Resumida:{" "}
										</span>
										{
											voucher.description
										}
									</p>
									<p>
										<span>
											Descrição
											Completa:{" "}
										</span>
										{
											voucher.fullDescription
										}
									</p>
								</div>
								<div id="moreInfoVoucher">
									<p>
										<span>
											Validade:{" "}
										</span>
										{
											voucher.expirationDate
										}
									</p>
									<p>
										<span>Ativo? </span>
										{voucher.status
											? "Sim"
											: "Não"}
									</p>
									<p>
										<span>
											Destaque?{" "}
										</span>
										{voucher.featured
											? "Sim"
											: "Não"}
									</p>
									<p>
										<span>
											Quantidade de
											usos:{" "}
										</span>
										{voucher.timesUsed}
									</p>
								</div>
							</div>
						) : (
							<NoVoucher key={NoVoucher} />
						)
					)}
				</div>
				<section className="storeEmployees noDisplay">
					<div className="userContainer">
						{!!employeesData ? (
							employeesData.map((employee) => (
								<div
									className="userCard"
									key={employee.id}
									onClick={() =>
										handleUserRedirect(
											employee.id
										)
									}
								>
									<div className="userPhoto">
										<p hidden>
											{employee.id}
										</p>
										{employee.profileImagePath ? (
											<img
												src={
													employee.profileImagePath
												}
												alt={`Foto de ${employee.name} ${employee.surname}`}
											/>
										) : (
											<img
												src={
													defaultImage
												}
												alt=""
											/>
										)}
									</div>
									<div className="userMoreInfo">
										<p>
											<span>
												Nome:{" "}
											</span>
											{
												employee.name
											}{" "}
											{
												employee.surname
											}
										</p>
										<p>
											<span>
												Cargo:{" "}
											</span>
											{
												employee.role
											}
										</p>
										<p>
											<span>
												e-mail:{" "}
											</span>
											{
												employee.email
											}
										</p>
										<p>
											<span>
												Telefone:{" "}
											</span>
											{
												employee.phoneNumber
											}
										</p>
									</div>
								</div>
							))
						) : (
							<NoEmployees key="noEmployees" />
						)}
					</div>
				</section>
			</section>
			<div id="deleteModal" className="noDisplay">
				<div id="deleteModalElements">
					<p>Deseja realmente excluir?</p>
					<div id="deleteButtons">
						<button
							onClick={handleDeleteModalConfimation}
						>
							<TiCancel className="icon" />
							<span>Cancelar</span>
						</button>
						<button onClick={handleDeleteStore}>
							<RiDeleteBin5Fill className="icon" />
							<span>Excluir</span>
						</button>
					</div>
				</div>
			</div>
			{isEditing && (
				<div id="editStoreModal">
					<div id="editStoreForm">
						<label htmlFor="newName">Nome</label>
						<input
							type="text"
							placeholder={storeData.storeName}
							name="newName"
							value={newStoreName}
							onChange={(e) =>
								setNewStoreName(e.target.value)
							}
						/>
						<label htmlFor="newCategory">
							Categoria
						</label>
						<input
							type="text"
							placeholder={storeData.category}
							name="newCategory"
							value={newCategory}
							onChange={(e) =>
								setNewCategory(e.target.value)
							}
						/>
						<label htmlFor="newImage">Imagem</label>
						<input
							type="file"
							accept="image/*"
							name="newImage"
							onChange={(e) =>
								setNewImage(e.target.files[0])
							}
						/>
						<div id="editButtonArea">
							<button onClick={isEditingModal}>
								Cancelar
							</button>
							<button onClick={handleConfirmUpdate}>
								Alterar
							</button>
						</div>
					</div>
				</div>
			)}
			{loading && <LoadingSpinner />}
		</main>
	);
};
