import React from "react";
import "./index.modules.css";

import cadimaImage from "../../defaulImage/noBackgroundCadimaLogo.jpeg";

export const Page404 = () => {
	return (
		<div id="background-404">
			<img
				src={cadimaImage}
				alt="logo"
			/>
			<h3>OPS!! Algo de errado ocorreu</h3>
			<p>Esta rota não existe na aplicação.</p>
			<a href="/home">
				<button>Voltar</button>
			</a>
		</div>
	);
};
