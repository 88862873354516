import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RiDeleteBin5Fill, RiFileEditFill, RiAdminFill } from "react-icons/ri";
import { MdOutlinePassword, MdEmail } from "react-icons/md";

import { BsPhoneFill } from "react-icons/bs";
import { TiCancel } from "react-icons/ti";

import "./index.modules.css";

import {
	getEmployee,
	patchPhoneID,
	deleteEmployee,
	patchPassword,
	patchEmail,
	patchPersonalInfo,
} from "../../api/Employees/employees";

import { getStores } from "../../api/Stores/stores";

import { TopMenu } from "../../components/TopMenu";
import { LoadingSpinner } from "../../components/LoadingSpinner";

import { deleteFolder } from "../../firebase/deleteFolderImage";
import { uploadImage } from "../../firebase/uploadImage";
import { AuthContext } from "../../contexts/auth";

export const User = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [employee, setEmployee] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [newPassword, setNewPassword] = useState("");
	const [modalPassword, setModalPassword] = useState(false);

	const [newEmail, setNewEmail] = useState("");
	const [modalEmail, setModalEmail] = useState(false);
	// // change
	// const [adminOption, setAdminOption] = useState(false);
	// const [changeAdmin, setChangeAdmin] = useState(false);

	const [editPersonalInfo, setEditPersonalInfo] = useState(false);

	const [newName, setNewName] = useState("");
	const [newSurname, setNewSurname] = useState("");
	const [newTelephone, setNewTelephone] = useState("");
	const [newImage, setNewImage] = useState("");
	const [newIdStore, setNewIdStore] = useState("");
	const [storesList, setStoresList] = useState([]);

	const { token } = useContext(AuthContext);

	useEffect(() => {
		async function getEmployeeData() {
			setLoading(true);
			try {
				const employeeData = await getEmployee(token, id);
				setEmployee(employeeData);
				const {stautsCode, stores} = await getStores(token);
				if(stautsCode === 200){
					const mappedStores = stores.map((store) => ({
						id: store.id,
						storeName: store.storeName,
					}));
					setStoresList(mappedStores);
				}
			} catch (error) {
				console.log(
					"Erro ao obter dados do colaborador:",
					error
				);
				setErrorMessage("Erro ao obter dados do colaborador.");
				setTimeout(() => {
					setErrorMessage("");
				}, 3000); // Defina o tempo em milissegundos que deseja que a mensagem permaneça visível (3 segundos neste exemplo)
			} finally {
				setLoading(false);
			}
		}
		getEmployeeData();
	}, [id]);

	const resetPhoneID = async (event) => {
		event.preventDefault();
		setLoading(true);
		try {
			await patchPhoneID(token, employee.id);
			setErrorMessage("ID do App resetado com sucesso.");
			setTimeout(() => {
				setErrorMessage("");
			}, 3000); // Defina o tempo em milissegundos que deseja que a mensagem permaneça visível (3 segundos neste exemplo)
		} catch (err) {
			console.log(err);
			setErrorMessage("Erro ao resetar ID do App.");
			setTimeout(() => {
				setErrorMessage("");
			}, 3000); // Defina o tempo em milissegundos que deseja que a mensagem permaneça visível (3 segundos neste exemplo)
		} finally {
			setLoading(false);
		}
	};

	const handleDeleteEmployee = async () => {
		setLoading(true);
		const fullName = `${employee.name} ${employee.surname}`;
		try {
			await deleteEmployee(token, employee.id);
			await deleteFolder(`users/${employee.idStore}/${fullName}`);
			navigate("/users");
		} catch (err) {
			console.log(err);
			setErrorMessage("Erro ao excluir o colaborador.");
			setTimeout(() => {
				setErrorMessage("");
			}, 3000); // Defina o tempo em milissegundos que deseja que a mensagem permaneça visível (3 segundos neste exemplo)
		} finally {
			setLoading(false);
			setConfirmDelete(false);
		}
	};

	const handleModalOnOff = () => {
		setConfirmDelete(!confirmDelete);
	};

	const handleModalPassword = () => {
		setModalPassword(!modalPassword);
		setNewPassword("");
	};

	const handleEditPassword = async () => {
		try {
			setLoading(true);
			if (!newPassword) {
				return;
			}
			await patchPassword(token, id, newPassword);
		} catch (err) {
			console.log(err);
		} finally {
			handleModalPassword();
			setLoading(false);
		}
		// console.log("Editar senha");
	};

	const handleModalEmail = () => {
		setModalEmail(!modalEmail);
		setNewEmail("");
	};

	const handleEditEmail = async () => {
		try {
			setLoading(true);
			if (!newEmail) {
				return;
			}
			const response = await patchEmail(token, id, newEmail);
			if (response.status === 200) {
				employee.email = newEmail;
			}
		} catch (err) {
			console.log(err);
		} finally {
			handleModalEmail();
			setLoading(false);
		}
	};

	// const handleEditAdmin = () => {
	// 	console.log("Editar admin");
	// };

	const handleModalPersonalInfo = () => {
		setEditPersonalInfo(!editPersonalInfo);
		setNewName("");
		setNewSurname("");
		setNewTelephone("");
		setNewImage("");
	};

	const handleEditPersonalInfo = async () => {
		// setLoading(true);
		/**
		 * Alterar o funcionamento para ajustar as informações corretamente e alterar a pasta no firebase corretamente
		 */
	};

	return (
		<main id="userPage">
			<TopMenu />
			<section id="userCard">
				<div id="userCardPhotoArea">
					<img src={employee.profileImagePath} alt="" />
				</div>
				<div id="userCardOtherInfo">
					<p className="boldTitle">
						Nome:{" "}
						<span className="thinText">{`${employee.name} ${employee.surname}`}</span>
					</p>
					<p className="boldTitle">
						Loja:{" "}
						<span className="thinText">
							{employee.storeName}
						</span>
					</p>
					<p className="boldTitle">
						Cargo:{" "}
						<span className="thinText">
							{employee.role}
						</span>
					</p>
					<p className="boldTitle">
						Email:{" "}
						<span className="thinText">
							{employee.email}
						</span>
					</p>
					<p className="boldTitle">
						Telefone:{" "}
						<span className="thinText">
							{employee.phoneNumber}
						</span>
					</p>
					<p className="boldTitle">
						Administrador do Sistema?{" "}
						<span className="thinText">
							{employee.admin ? "Sim" : "Não"}
						</span>
					</p>
				</div>
				<div id="userOptions">
					<h4>Ações</h4>
					<div id="buttonArea">
						<div>
							<button
								className="actionButtonUserPage"
								id="editPasswordButton"
								onClick={() =>
									handleModalPassword()
								}
							>
								<MdOutlinePassword className="iconUserPage" />
								Alterar Senha
							</button>
							<button
								className="actionButtonUserPage"
								id="editEmailButton"
								onClick={handleModalEmail}
							>
								<MdEmail className="iconUserPage" />
								Alterar Email
							</button>
							{/* <button
								className="actionButtonUserPage"
								id="editAdminButton"
								onClick={handleEditAdmin}
								disabled
							>
								<RiAdminFill className="iconUserPage" />
								Alterar Admin
							</button> */}
						</div>
						<div>
							<button
								className="actionButtonUserPage"
								id="editPersonalInfoButton"
								onClick={
									handleModalPersonalInfo
								}
							>
								<RiFileEditFill className="iconUserPage" />
								Alterar Info. Pessoais
							</button>
							<button
								className="actionButtonUserPage"
								id="resetButton"
								onClick={resetPhoneID}
							>
								<BsPhoneFill className="iconUserPage" />
								Resetar ID do App
							</button>
							<button
								className="actionButtonUserPage"
								id="deleteButton"
								onClick={handleModalOnOff}
							>
								<RiDeleteBin5Fill className="iconUserPage" />
								Excluir
							</button>
						</div>
					</div>
				</div>
			</section>
			{confirmDelete && (
				<div id="deleteUserModal">
					<div id="deleteUserModalElements">
						<p>Deseja realmente excluir?</p>
						<div id="deleteUserButtons">
							<button
								onClick={handleModalOnOff}
								className="buttonModalUser"
							>
								<TiCancel className="icon" />
								<span>Cancelar</span>
							</button>
							<button
								className="buttonModalUser"
								onClick={handleDeleteEmployee}
							>
								<RiDeleteBin5Fill className="icon" />
								<span>Excluir</span>
							</button>
						</div>
					</div>
				</div>
			)}
			{modalPassword && (
				<div className="modalPasswordArea">
					<div className="modalPassword">
						<label htmlFor="newPassword">
							Nova Senha
						</label>
						<input
							type="text"
							name="newPassword"
							placeholder="Nova senha"
							value={newPassword}
							onChange={(e) =>
								setNewPassword(e.target.value)
							}
						/>
						<div className="buttonPasswordArea">
							<button
								onClick={() =>
									handleModalPassword()
								}
							>
								Cancelar
							</button>
							<button
								onClick={() =>
									handleEditPassword()
								}
							>
								Salvar
							</button>
						</div>
					</div>
				</div>
			)}
			{modalEmail && (
				<div className="modalEmailArea">
					<div className="modalEmail">
						<label htmlFor="newEmail">Nova Senha</label>
						<input
							type="text"
							name="newEmail"
							placeholder="Novo Email"
							value={newEmail}
							onChange={(e) =>
								setNewEmail(e.target.value)
							}
						/>
						<div className="buttonEmailArea">
							<button
								onClick={() =>
									handleModalEmail()
								}
							>
								Cancelar
							</button>
							<button
								onClick={() =>
									handleEditEmail()
								}
							>
								Salvar
							</button>
						</div>
					</div>
				</div>
			)}
			{editPersonalInfo && (
				<div className="modalEditPersonalInfoArea">
					<div className="modalEditPersonalInfo">
						<label htmlFor="newName">Nome</label>
						<input
							type="text"
							name="newName"
							placeholder="Alterar Nome"
							value={newName}
							onChange={(e) =>
								setNewName(e.target.value)
							}
						/>
						<label htmlFor="newSurname">
							Sobrenome
						</label>
						<input
							type="text"
							name="newSurname"
							placeholder="Alterar Sobrenome"
							value={newSurname}
							onChange={(e) =>
								setNewSurname(e.target.value)
							}
						/>
						<label htmlFor="newTelephone">
							Telefone
						</label>
						<input
							type="text"
							name="newTelephone"
							maxLength="15"
							placeholder="(xx) xxxxx-xxxx"
							value={newTelephone}
							onChange={(e) =>
								setNewTelephone(e.target.value)
							}
						/>
						<label htmlFor="newImage">Nova Foto</label>
						<input
							type="file"
							name="newImage"
							accept="image/*"
							onChange={(e) =>
								setNewImage(e.target.files[0])
							}
						/>
						<label htmlFor="newStore">
							Alterar Loja
						</label>
						<select
							name="newStore"
							id="storeSelect"
							value={newIdStore}
							onChange={(e) =>
								setNewIdStore(e.target.value)
							}
						>
							<option value="">
								Selecione uma loja
							</option>
							{storesList.map((store) => (
								<option
									key={store.id}
									value={store.id}
								>
									{store.storeName}
								</option>
							))}
						</select>
						<div className="modalEditPersonalInfoButtons">
							<button
								onClick={() =>
									handleModalPersonalInfo()
								}
							>
								Cancelar
							</button>
							<button
								onClick={() =>
									handleEditPersonalInfo()
								}
							>
								Alterar
							</button>
						</div>
					</div>
				</div>
			)}
			{loading && <LoadingSpinner />}
			{errorMessage && <p>{errorMessage}</p>}
		</main>
	);
};
