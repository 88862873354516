import { AxiosWithoutAuthToken } from "../config";

export const loginAdmin = async (email, password) => {
	try {
		const response = await AxiosWithoutAuthToken().post(
			`/login/Admin`,
			{
				email,
				password,
			}
		);

		if(response){
			console.log(response.data);
			return { statusCode: response.status, userData: response.data };
		}else{
			throw new Error("Algo deu errado");
		}

	} catch (err) {
		throw err;
	}
};
