import React from "react";
import { TailSpin } from "react-loader-spinner";
import "./index.modules.css";

export const LoadingSpinner = ({ color = "#e8191d", height = 80, width = 80 }) => {
  return (
    <div className="loading-spinner-overlay">
      <div className="loading-spinner">
        <TailSpin color={color} height={height} width={width} />
      </div>
    </div>
  );
};