import React, { useContext, useEffect, useState } from "react";

import "./index.modules.css";

import { TopMenu } from "../../components/TopMenu";
import { CardStore } from "../../components/CardStore";
import { getStores, postStore } from "../../api/Stores/stores";
import { RiAddBoxFill } from "react-icons/ri";
import { uploadImage } from "../../firebase/uploadImage";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { AuthContext } from "../../contexts/auth";

export const Stores = () => {
	const [storesList, setStoresList] = useState([]);
	const [search, setSearch] = useState("");

	const [newName, setNewName] = useState("");
	const [newCategory, setNewCategory] = useState("");
	const [newImage, setNewImage] = useState(null);

	const [loading, setLoading] = useState(true);

	const { token } = useContext(AuthContext);

	useEffect(() => {
		async function getData() {
			setLoading(true);
			try {
				const { stautsCode, stores } = await getStores(token);

				if (stautsCode === 200) {
					setStoresList(stores);
				}
			} catch (err) {
				console.log(err);
			}
			setLoading(false);
		}
		getData();
		//eslint-disable-next-line
	}, []);

	const handleSearchText = (e) => {
		const { value } = e.target;
		setSearch(value);
	};

	const handleModal = () => {
		const modal = document.getElementById("modalAddStore");

		modal.classList.toggle("noDisplay");
	};

	const handleAddStore = async () => {
		setLoading(true);
		if (newImage) {
			try {
				const imageURL = await uploadImage(
					newImage,
					newImage.name,
					"stores",
					newName
				);
				await postStore(token, newName, newCategory, imageURL);

				const { stores } = await getStores(token);
				setStoresList(stores);

				setNewName("");
				setNewCategory("");
				setNewImage(null);
			} catch (error) {
				setNewName("");
				setNewCategory("");
				setNewImage(null);

				console.error("Erro ao enviar a imagem:", error);
			} finally {
				setLoading(false);
			}
		}
		handleModal();
	};

	const filteredStores = storesList
		? storesList.filter((store) =>
				store.storeName
					.toLowerCase()
					.includes(search.toLowerCase())
		  )
		: [];

	const renderStores = search !== "" ? filteredStores : storesList;

	return (
		<main id="content-stores">
			<TopMenu />
			<h3>Lojas</h3>
			<nav id="actionMenu">
				<input
					type="search"
					name=""
					id="search-stores"
					placeholder="Pesquisar Lojas"
					value={search}
					onChange={handleSearchText}
				/>
				<div id="buttonArea">
					<button id="addStoreButton" onClick={handleModal}>
						<RiAddBoxFill id="addIcon" />
						<span>Nova Loja</span>
					</button>
				</div>
			</nav>
			<nav id="cardStoresArea">
				{renderStores &&
					renderStores.map((store) => (
						<CardStore
							key={store.id}
							route={"store"}
							id={store.id}
							name={store.storeName}
							logoPath={store.logoPath}
						/>
					))}
			</nav>
			<div id="modalAddStore" className="noDisplay">
				<div id="addStoreContainer">
					<form id="addStoreForm">
						<label htmlFor="storeName">
							Nome da Loja
						</label>
						<input
							id="storeNameInput"
							type="storeName"
							name="storeName"
							placeholder="Nome da loja aqui"
							value={newName}
							onChange={(e) =>
								setNewName(e.target.value)
							}
						/>
						<label htmlFor="category">Categoria</label>
						<input
							id="categoryInput"
							type="category"
							name="category"
							placeholder="Digite a categoria da loja aqui"
							value={newCategory}
							onChange={(e) =>
								setNewCategory(e.target.value)
							}
						/>
						<label htmlFor="storeImage">
							Foto da Loja
						</label>
						<input
							type="file"
							accept="image/*"
							id="storeImageInput"
							name="storeImage"
							onChange={(e) =>
								setNewImage(e.target.files[0])
							}
						/>
					</form>
					<div id="buttonArea">
						<button
							className="buttonsModal"
							id="save"
							onClick={handleAddStore}
						>
							Salvar
						</button>
						<button
							className="buttonsModal"
							id="cancel"
							onClick={handleModal}
						>
							Cancelar
						</button>
					</div>
				</div>
			</div>
			{loading && <LoadingSpinner />}
		</main>
	);
};
