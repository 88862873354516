import React from "react";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../defaulImage/noImage.png";

import "./index.modules.css";

export const CardStore = (props) => {
	const navigate = useNavigate();

	const userOrStore = props.route === "store" ? "store" : "user";

	return (
		<div onClick={() => navigate(`/${userOrStore}/${props.id}`, {id: props.id, storeLogo: props.logoPath, name: props.storeName})} className="cardStore">
			{props.logoPath ? <img src={`${props.logoPath}`} alt="" /> : <img src={defaultImage} alt="" />}
			<div>
				<p>{props.name}</p>
			</div>
		</div>
	);
};
