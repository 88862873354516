import React, { useState, useContext } from "react";

import "./index.modules.css";
import { AuthContext } from "../../contexts/auth";
import { LoadingSpinner } from "../../components/LoadingSpinner";

export const Login = (props) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const { login } = useContext(AuthContext);

	const handleLogin = async (event) => {
		event.preventDefault();
		setErrorMessage("");

		try {
			setIsLoading(true);
			await login(email, password);
			setIsLoading(false);
		} catch (err) {
			console.log(err);
			setErrorMessage(
				"Usuário não é permitido a entrar no sistema"
			);
			setIsLoading(false);
		}
	};

	return (
		<main id="content">
			<section id="form-container">
				<img
					id="logo"
					src="./assets/icon/cadima.svg"
					alt="Logo Cadima"
				/>
				<form id="form-login" onSubmit={handleLogin}>
					<label htmlFor="email">Email</label>
					<input
						id="email"
						type="email"
						name="email"
						placeholder="Digite seu email"
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
					/>
					<label htmlFor="password">Senha</label>
					<input
						id="password"
						type="password"
						name="password"
						placeholder="Digite sua senha"
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
						}}
					/>
					<input
						type="submit"
						value="Entrar"
						id="btn-login"
					/>
					{errorMessage && <p className="error-message">{errorMessage}</p>}
				</form>
			</section>
			{isLoading && <LoadingSpinner />}
		</main>
	);
};
