import { AxiosWithAuthToken } from "../config";

export const postVoucher = async (
	token,
	idStore,
	description,
	fullDescription,
	expirationDate,
	status,
	featured
) => {
	try {
		const response = await AxiosWithAuthToken(token).post(`/vouchers`, {
			idStore,
			description,
			fullDescription,
			expirationDate,
			status,
			featured,
		});
		return response;
	} catch (error) {
		console.log(error);
		throw new Error("Erro ao cadastrar o cupom");
	}
};

export const getAllVouchers = async (token) => {
	try {
		const response = await AxiosWithAuthToken(token).get(`/vouchers`);
		
		return {statusCode: response.status, data: response.data};
	} catch (err) {
		throw err;
	}
}

export const getVoucher = async (token, id) => {
	try{
		const response = await AxiosWithAuthToken(token).get(`/vouchers/${id}`);

		return response;
	}catch(err){
		throw err;
	}
}