import React from "react";

import "./index.modules.css";

import { HiEmojiSad } from "react-icons/hi";

export const NoEmployees = () => {
	return (
		<div id="noEmployees">
			Essa Loja ainda colaboradores cadastrados...{" "}
			<HiEmojiSad id="emoji" />
		</div>
	);
};
