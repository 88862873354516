// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyA2Z19h0EcQdukyipTra7gSdzjktN64bJk",
//   authDomain: "familiacadima-1076a.firebaseapp.com",
//   databaseURL: "https://familiacadima-1076a-default-rtdb.firebaseio.com",
//   projectId: "familiacadima-1076a",
//   storageBucket: "familiacadima-1076a.appspot.com",
//   messagingSenderId: "321112035329",
//   appId: "1:321112035329:web:fe072fd1aac7ab58f1f000"
// };

/**
 * alcas cadima
 */
const firebaseConfig = {
	apiKey: "AIzaSyBCW0stFWlJyBL-SiiiYf4thFIUUSw4xCs",
	authDomain: "familiacadimaweb.firebaseapp.com",
	projectId: "familiacadimaweb",
	storageBucket: "familiacadimaweb.appspot.com",
	messagingSenderId: "316631903992",
	appId: "1:316631903992:web:1b82f16451a0d5b66bd50d",
};

/**
 *
 */
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
