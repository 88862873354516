import { ref, uploadBytes, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { storage } from "./config";

export const uploadImage = async (file, filename, type, name) => {
  const storageRef = ref(storage, `${type}/${name}/${filename}`);

  try {
    // Lista todos os itens na pasta
    const listResult = await listAll(storageRef.parent);

    // Exclui cada item na pasta
    const deletePromises = listResult.items.map((item) => deleteObject(item));

    // Aguarda a exclusão de todos os itens
    await Promise.all(deletePromises);

    // Faz o upload da nova imagem
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL;
  } catch (error) {
    // Se ocorrer um erro, significa que a pasta está vazia
    // Podemos fazer o upload normalmente

    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL;
  }
};