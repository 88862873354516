import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./index.modules.css";

import { TopMenu } from "../../components/TopMenu";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { CardStore } from "../../components/CardStore";

import { RiAddBoxFill } from "react-icons/ri";

import { getEmployees, postEmployee } from "../../api/Employees/employees";
import { uploadImage } from "../../firebase/uploadImage";
import { getStores } from "../../api/Stores/stores";
import { AuthContext } from "../../contexts/auth";

export const Users = () => {
	const navigate = useNavigate();
	const [employees, setEmployees] = useState([]);
	const [storesList, setStoresList] = useState([]);

	const [newIdStore, setNewIdStore] = useState("");
	const [newName, setNewName] = useState("");
	const [newSurname, setNewSurname] = useState("");
	const [newEmail, setNewEmail] = useState("");
	const [newPhone, setNewPhone] = useState("");
	const [newRole, setNewRole] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [isAdmin, setIsAdmin] = useState(false);
	const [formError, setFormError] = useState(false);

	const [newProfilePhoto, setNewProfilePhoto] = useState("");

	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);

	const { token } = useContext(AuthContext);

	useEffect(() => {
		async function fetchEmployees() {
			try {
				const employeesData = await getEmployees(token);
				setEmployees(employeesData);
	
				const {stautsCode, stores} = await getStores(token);
				if(stautsCode === 200) {
					const mappedStores = stores.map((store) => ({
						id: store.id,
						storeName: store.storeName,
					}));
					setStoresList(mappedStores);
				}
			} catch (err) {
				console.log(err);
			}
		}
		fetchEmployees();
		//eslint-disable-next-line
	}, []);

	const handleModal = () => {
		const modal = document.getElementById("modalAddUser");

		modal.classList.toggle("noDisplay");

		setNewIdStore("");
		setNewName("");
		setNewSurname("");
		setNewEmail("");
		setNewPhone("");
		setNewRole("");
		setNewPassword("");
		setNewProfilePhoto("");
		setIsAdmin(false);
		setFormError(false);
	};

	const handlePhoneChange = (event) => {
		const phoneValue = event.target.value;

		// Remove todos os caracteres não numéricos
		const cleanedValue = phoneValue.replace(/\D/g, "");

		let formattedValue = "";

		if (cleanedValue.length <= 2) {
			formattedValue = cleanedValue;
		} else if (cleanedValue.length <= 7) {
			formattedValue = cleanedValue.replace(
				/^(\d{2})(\d{0,5})/,
				"($1) $2"
			);
		} else {
			formattedValue = cleanedValue.replace(
				/^(\d{2})(\d{5})(\d{0,4})/,
				"($1) $2-$3"
			);
		}

		setNewPhone(formattedValue); // Salva o valor com a máscara
	};

	const handleCheckboxChange = () => {
		setIsAdmin(!isAdmin);
	};

	const handleSaveUser = async () => {
		if (
			!newName ||
			!newSurname ||
			!newEmail ||
			!newPhone ||
			!newIdStore ||
			!newPassword
		) {
			setFormError(true);
			return;
		}

		// Restante do código para salvar o usuário
		setLoading(true);

		if (newProfilePhoto) {
			try {
				const fullName = newName + " " + newSurname;
				const imageURL = await uploadImage(
					newProfilePhoto,
					newProfilePhoto.name,
					`users/${newIdStore}`,
					fullName
				);

				const cleanPhone = newPhone.replace(/\D/g, "");
				await postEmployee(
					token,
					newIdStore,
					newName,
					newSurname,
					newEmail,
					cleanPhone,
					newRole,
					newPassword,
					isAdmin,
					imageURL
				);

				try {
					const employeesData = await getEmployees(token);
					setEmployees(employeesData);
				} catch (err) {
					console.log(err);
					navigate("/users");
					return;
				}
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		}
		handleModal();
	};

	const filteredEmployees = employees
		? employees.filter((e) =>
				e.fullName
					.toLowerCase()
					.includes(search.toLocaleLowerCase())
		  )
		: [];

	const renderEmployees = search !== "" ? filteredEmployees : employees;

	return (
		<main id="userPageContent">
			<TopMenu />
			<h3>Colaboradores</h3>
			<section id="employeesContainer">
				<div id="searchEmployeesArea">
					<input
						id="searchUserInput"
						type="search"
						placeholder="Digite o nome de um colaborador"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
					<button
						id="addEmployeesButton"
						onClick={handleModal}
					>
						<RiAddBoxFill id="addIcon" />
						<span>Novo Colaborador</span>
					</button>
				</div>
				<div id="cardArea">
					{!!employees ? (
						renderEmployees.map((e) => (
							<CardStore
								key={e.id}
								route={"user"}
								id={e.id}
								name={e.fullName}
								logoPath={e.profileImagePath}
							/>
						))
					) : (
						<p>não tem funcionários</p>
					)}
				</div>
			</section>
			<div id="modalAddUser" className="noDisplay">
				<div id="addStoreUser">
					<form id="addUserForm">
						<label htmlFor="userName">Nome</label>
						<input
							className="userInput"
							id="nameInput"
							type="text"
							name="userName"
							placeholder="Primeiro Nome"
							value={newName}
							onChange={(e) =>
								setNewName(e.target.value)
							}
						/>
						{formError && !newName && (
							<p className="formError">
								O campo Nome é obrigatório.
							</p>
						)}
						<label htmlFor="surname">Sobrenome</label>
						<input
							className="userInput"
							id="surnameInput"
							type="text"
							name="surname"
							placeholder="Digite o sobrenome aqui"
							value={newSurname}
							onChange={(e) =>
								setNewSurname(e.target.value)
							}
						/>
						{formError && !newSurname && (
							<p className="formError">
								O campo Sobrenome é obrigatório.
							</p>
						)}
						<label htmlFor="email">Email</label>
						<input
							className="userInput"
							id="emailInput"
							type="email"
							name="email"
							placeholder="Digite o email aqui"
							value={newEmail}
							onChange={(e) =>
								setNewEmail(e.target.value)
							}
						/>
						{formError && !newEmail && (
							<p className="formError">
								O campo Email é obrigatório.
							</p>
						)}
						<label htmlFor="phone">Telefone</label>
						<input
							className="userInput"
							id="phoneInput"
							type="text"
							name="phone"
							maxLength="15"
							placeholder="(xx) xxxxx-xxxx"
							value={newPhone}
							onChange={handlePhoneChange}
						/>
						{formError && !newPhone && (
							<p className="formError">
								O campo Telefone é obrigatório.
							</p>
						)}
						<label htmlFor="storeSelect">
							Selecione uma loja:
						</label>
						<select
							id="storeSelect"
							value={newIdStore}
							onChange={(e) =>
								setNewIdStore(e.target.value)
							}
						>
							<option value="">
								Selecione uma loja
							</option>
							{storesList.map((store) => (
								<option
									key={store.id}
									value={store.id}
								>
									{store.storeName}
								</option>
							))}
						</select>
						{formError && !newIdStore && (
							<p className="formError">
								A loja é obrigatória.
							</p>
						)}
						<label htmlFor="role">Cargo</label>
						<input
							className="userInput"
							id="roleInput"
							type="text"
							name="role"
							placeholder="Digite o cargo aqui"
							value={newRole}
							onChange={(e) =>
								setNewRole(e.target.value)
							}
						/>
						<label htmlFor="userImage">Foto</label>
						<input
							className="userInput"
							type="file"
							accept="image/*"
							id="storeImageInput"
							name="userImage"
							onChange={(e) =>
								setNewProfilePhoto(
									e.target.files[0]
								)
							}
						/>
						<label htmlFor="password">Senha</label>
						<input
							className="userInput"
							id="passwordInput"
							type="text"
							name="password"
							placeholder="Digite a senha aqui"
							value={newPassword}
							onChange={(e) =>
								setNewPassword(e.target.value)
							}
						/>
						{formError && !newPassword && (
							<p className="formError">
								O campo Senha é obrigatório.
							</p>
						)}
						<div id="inputInline">
							<label htmlFor="surname">
								Admin?
							</label>
							<input
								className="userInput"
								id="surnameInput"
								type="checkbox"
								name="surname"
								placeholder="Digite a categoria da loja aqui"
								checked={isAdmin}
								onChange={handleCheckboxChange}
							/>
						</div>
					</form>
					<div id="buttonArea">
						<button
							className="buttonsModal"
							id="save"
							onClick={handleSaveUser}
						>
							Salvar
						</button>
						<button
							className="buttonsModal"
							id="cancel"
							onClick={handleModal}
						>
							Cancelar
						</button>
					</div>
				</div>
			</div>
			{loading && <LoadingSpinner />}
		</main>
	);
};
