import React from "react";

import "./index.modules.css";

import { HiEmojiSad } from "react-icons/hi";

export const NoVoucher = () => {
	return (
		<div id="noVoucher">
			Essa Loja ainda não possui cupons disponíveis...{" "}
			<HiEmojiSad id="emoji" />
		</div>
	);
};
