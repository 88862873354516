import axios from "axios";

const URL = 'https://srv472084.hstgr.cloud:3002';
// const URL = 'http://localhost:3001';

const baseConfig = {
	baseURL: URL,
	signal: new AbortController().signal
};

export const AxiosWithAuthToken = (token) => {
	const config = {
		...baseConfig,
		headers: {
			Authorization: token
		},
		timeout: 10000
	};

	return axios.create(config);
}

export const AxiosWithoutAuthToken = () => {
	const config = {...baseConfig, timeout: 10000};

	return axios.create(config);
}