import React, { useContext } from "react";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

import "./index.css";

import { AuthProvider, AuthContext } from "./contexts/auth";
import { Login } from "./templates/Login";
import { Home } from "./templates/Home";
import { Page404 } from "./templates/Page404";
import { Stores } from "./templates/Stores";
import { Store } from "./templates/Store";
import { Users } from "./templates/Users";
import { User } from "./templates/User";
import { Ranking } from "./templates/Ranking";
import { Vouchers } from "./templates/Vouchers";
import { Voucher } from "./templates/Voucher";

export const AppRoutes = () => {
	const Private = ({ children }) => {
		const { authenticated, loading } = useContext(AuthContext);

		if (loading) {
			return <div className="loading">Carregando...</div>;
		}

		if (!authenticated) {
			return <Navigate to="/" />;
		}

		return children;
	};

	return (
		<Router>
			<AuthProvider>
				<Routes>
					<Route exact path="/" element={<Login />} />
					<Route
						exact
						path="/home"
						element={
							<Private>
								<Home />
							</Private>
						}
					/>
					<Route
						exact
						path="/stores"
						element={
							<Private>
								<Stores />
							</Private>
						}
					/>
					<Route
						exact
						path="/store/:id"
						element={
							<Private>
								<Store />
							</Private>
						}
					/>
					<Route
						exact
						path="/users"
						element={
							<Private>
								<Users />
							</Private>
						}
					/>
					<Route
						exact
						path="/user/:id"
						element={
							<Private>
								<User />
							</Private>
						}
					/>
					<Route
						exact
						path="/vouchers"
						element={
							<Private>
								<Vouchers />
							</Private>
						}
					></Route>
					<Route
						exact
						path="/voucher/:id"
						element={
							<Private>
								<Voucher />
							</Private>
						}
					>
					</Route>
					<Route
						exact
						path="/ranking"
						element={
							<Private>
								<Ranking />
							</Private>
						}
					></Route>
					<Route path="*" element={<Page404 />} />
				</Routes>
			</AuthProvider>
		</Router>
	);
};
