import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { MdLogout } from "react-icons/md";
import { FaStore, FaUsers } from "react-icons/fa";

import "./index.modules.css";
import { AuthContext } from "../../contexts/auth";

export const Home = () => {
	const navigate = useNavigate();

	const { name, surname, logout } = useContext(AuthContext);

	const handleLogout = () => {
		console.log("sair");
		logout();
	};

	return (
		<main id="content-home">
			<h3>Olá, {name} {surname}</h3>
			<nav id="card-menu">
				<div
					className="optionCard"
					onClick={() => navigate("/stores")}
				>
					<FaStore className="iconHome" />
					<div>
						<p className="cardText">Lojas</p>
					</div>
				</div>
				<div
					className="optionCard"
					onClick={() => navigate("/users")}
				>
					<FaUsers className="iconHome" />
					<div>
						<p className="cardText">Usuários</p>
					</div>
				</div>
				<div className="optionCard" onClick={handleLogout}>
					<MdLogout className="iconHome" />
					<div>
						<p className="cardText">Sair</p>
					</div>
				</div>
			</nav>
		</main>
	);
};
