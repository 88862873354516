import { AxiosWithAuthToken } from "../config";

export const getStores = async (token) => {
	try {
		const response = await AxiosWithAuthToken(token).get(`/stores`);
		
		return { stautsCode: response.status, stores: response.data };
	} catch (err) {
		throw err;
	}
};

export const getStore = async (token, id) => {
	try {
		const response = await AxiosWithAuthToken(token).get(
			`/stores/${id}`
		);

		return { stautsCode: response.status, data: response.data };
	} catch (err) {
		throw err;
	}
};

export const getStoreVouchers = async (token, id) => {
	const responseData = await AxiosWithAuthToken(token)
		.get(`/stores/${id}/vouchers`)
		.then((response) => response.data)
		.catch((err) => console.log(err));

	return responseData;
};

export const getStoreEmployees = async (token, id) => {
	const responseData = await AxiosWithAuthToken(token)
		.get(`/stores/${id}/employees`)
		.then((response) => response.data)
		.catch((err) => console.log(err));

	return responseData;
};

export const postStore = async (token, storeName, category, imagePath) => {
	const response = await AxiosWithAuthToken(token)
		.post(`/stores`, {
			storeName,
			category,
			imagePath,
		})
		.then((response) => response)
		.catch((err) => console.log(err));

	return response;
};

export const deleteStore = async (token, idStore) => {
	const response = await AxiosWithAuthToken(token)
		.delete(`/stores/${idStore}`)
		.then((response) => response.status)
		.catch((err) => err);

	return response;
};

export const patchStore = async (
	token,
	idStore,
	newName = "",
	newCategory = "",
	newLogoPath = ""
) => {
	console.log("newImage:", newLogoPath);
	const response = await AxiosWithAuthToken(token)
		.patch(`/stores/${idStore}`, {
			newName,
			newCategory,
			newLogoPath,
		})
		.then((response) => response.status)
		.catch((err) => err);

	return response;
};
