import { ref, listAll, deleteObject } from "firebase/storage";
import { storage } from "./config";

export const deleteFolder = async (folderPath) => {
	const folderRef = ref(storage, folderPath);

	try {
		// Lista todos os itens na pasta
		const listResult = await listAll(folderRef);

		// Exclui cada item na pasta (arquivos e subpastas)
		const deletePromises = listResult.items.map((item) =>
			deleteObject(item)
		);

		// Aguarda a exclusão de todos os itens
		await Promise.all(deletePromises);

		// Exclui a pasta principal
		await deleteObject(folderRef);

		console.log("Pasta excluída com sucesso!");
	} catch (error) {
		console.log("Erro ao excluir a pasta:", error);
	}
};
