import React, { useContext, useEffect, useState } from "react";

import "./index.modules.css";

import { TopMenu } from "../../components/TopMenu";

import { getAllVouchers, postVoucher } from "../../api/Voucher/vouchers";
import { CardVoucher } from "../../components/CardVoucher";
import { AuthContext } from "../../contexts/auth";
import { RiAddBoxFill } from "react-icons/ri";
import { getStores } from "../../api/Stores/stores";
import {LoadingSpinner} from "../../components/LoadingSpinner";

const formInitialState = {
	description: "",
	fullDescription: "",
	storeId: null,
	expirationDate: "",
	status: true,
	featured: false,
}

export const Vouchers = () => {
	const [vouchers, setVouchers] = useState([]);
	const [searchVouchers, setSearchVouchers] = useState("");
	const [formValues, setFormValues] = useState({
		description: "",
		fullDescription: "",
		storeId: null,
		expirationDate: "",
		status: true,
		featured: false,
	});
	const [storesList, setStoresList] = useState([]);
	const [isModalOn, setIsModalOn] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { token } = useContext(AuthContext);

	useEffect(() => {
		async function getAllVouchersData() {
			try {
				const { statusCode, data } = await getAllVouchers(
					token
				);
				if (statusCode === 200) {
					setVouchers(data);
				}
			} catch (err) {
				console.log(err);
			}
		}
		getAllVouchersData();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		async function getStoresList() {
			setIsLoading(true);

			try {
				const { stores } = await getStores(token);
				const mappedStores = stores.map((store) => ({
					id: store.id,
					storeName: store.storeName,
				}));
				setStoresList(mappedStores);
			} catch (err) {
				console.log(err);
			}
			setIsLoading(false);
		}
		getStoresList();
		// eslint-disable-next-line
	}, []);

	const filteredVouchers = vouchers
		? vouchers.filter((voucher) =>
				voucher.description
					.toLowerCase()
					.includes(searchVouchers.toLocaleLowerCase())
		  )
		: [];

	const renderVouchers =
		searchVouchers !== "" ? filteredVouchers : vouchers;

	const handleAddVoucherModalOnOff = () => {
		setIsModalOn(!isModalOn);
	};

	const handleSelectChange = (e) => {
		const { name, value } = e.target;

		setFormValues((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleFormChange = (e) => {
		const { name, value, type, checked } = e.target;

		const newValue = type === "checkbox" ? checked : value;

		setFormValues((prevState) => ({
			...prevState,
			[name]: newValue,
		}));
	};

	const handleAddNewVoucher = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			const response = await postVoucher(
				token,
				formValues.storeId,
				formValues.description,
				formValues.fullDescription,
				formValues.expirationDate,
				formValues.status,
				formValues.featured
			);
			console.log({formValues});
			console.log(response.status, typeof(response.status));
			if(response.status === 200 || "200"){
				console.log("cupom salvo");
				handleAddVoucherModalOnOff();
				const { statusCode, data } = await getAllVouchers(
					token
				);
				if (statusCode === 200) {
					setVouchers(data);
				}
				setFormValues((prevState) => ({
					...prevState,
					...formInitialState
				}));
			}
		} catch (err) {
			console.log(err);
		}
		setIsLoading(false);
	};

	return (
		<main className="vouchersPage">
			<TopMenu />
			<h3>Todos os Cupons</h3>
			<section id="searchVouchersArea">
				<input
					className="searchVoucher"
					placeholder="Filtrar pela descrição"
					type="search"
					value={searchVouchers}
					onChange={(e) =>
						setSearchVouchers(e.target.value)
					}
				/>
				<button
					id="addVouchersButton"
					onClick={handleAddVoucherModalOnOff}
				>
					<RiAddBoxFill id="addIcon" />
					<span>Novo Cupom</span>
				</button>
			</section>
			<nav className="voucherNavigation">
				{renderVouchers.map((v) => (
					<CardVoucher
						key={v.id}
						id={v.id}
						description={v.description}
						fullDescription={v.fullDescription}
						expirationDate={v.expirationDate}
						status={v.status}
						featured={v.featured}
						idStore={v.idStore}
						storeName={v.storeName}
						logoPath={v.logoPath}
					/>
				))}
				{isModalOn ? (
					<div id="addVoucherModalScreen">
						<div id="addVoucherForm">
							<h2>Novo Cupom</h2>
							<form
								id="addVoucherWithoutStoreSet"
								onSubmit={handleAddNewVoucher}
							>
								<div>
									<label htmlFor="description">
										Descrição Resumida:
									</label>
									<input
										type="text"
										id="description"
										name="description"
										value={
											formValues.description
										}
										onChange={
											handleFormChange
										}
									/>
								</div>
								<div>
									<label htmlFor="fullDescription">
										Descrição Completa:
									</label>
									<br />
									<textarea
										id="fullDescription"
										name="fullDescription"
										value={
											formValues.fullDescription
										}
										onChange={
											handleFormChange
										}
									></textarea>
								</div>
								<div>
									<label htmlFor="storeId">
										Loja:
									</label>
									<select
										name="storeId"
										id="storeSelect"
										value={
											formValues.storeId
										}
										onChange={
											handleSelectChange
										}
									>
										<option
											value={null}
											defaultValue
										>
											Selecione uma
											loja
										</option>
										{storesList.map(
											(store) =>
												store.id ===
													1 ||
												store.storeName ===
													"ALCAS" ? null : (
													<option
														key={
															store.id
														}
														value={
															store.id
														}
													>
														{
															store.storeName
														}
													</option>
												)
										)}
									</select>
								</div>
								<div>
									<label htmlFor="expirationDate">
										Validade:
									</label>
									<input
										type="date"
										id="expirationDate"
										name="expirationDate"
										value={
											formValues.expirationDate
										}
										onChange={
											handleFormChange
										}
									/>
								</div>
								<div className="modalCheckbox">
									<label htmlFor="status">
										Ativo?
									</label>
									<input
										type="checkbox"
										id="status"
										name="status"
										checked={
											formValues.status
										}
										onChange={
											handleFormChange
										}
									/>
									<label htmlFor="featured">
										Destaque?
									</label>
									<input
										type="checkbox"
										id="featured"
										name="featured"
										checked={
											formValues.featured
										}
										onChange={
											handleFormChange
										}
									/>
								</div>
								<div className="buttonContainer">
									<button
										className="cancelButton"
										onClick={
											handleAddVoucherModalOnOff
										}
									>
										Cancelar
									</button>
									<button
										className="submitButton"
										type="submit"
									>
										Cadastrar
									</button>
								</div>
							</form>
						</div>
					</div>
				) : (
					""
				)}
				{isLoading ? <LoadingSpinner/> : null}
			</nav>
		</main>
	);
};
