import React, { useContext, useEffect, useState } from "react";

import "./index.modules.css";

import { TopMenu } from "../../components/TopMenu";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { getVoucher } from "../../api/Voucher/vouchers";

import { BsCalendar2DateFill } from "react-icons/bs";
import { MdVerified, MdRemoveCircle, MdDescription } from "react-icons/md";
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AuthContext } from "../../contexts/auth";
// import { hiAnnotation } from "react-icons/hi";

export const Voucher = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [voucherData, setVoucherData] = useState({});

	const { token } = useContext(AuthContext);

	useEffect(() => {
		async function getVoucherData() {
			setLoading(true);
			try {
				const voucherData = await getVoucher(token, id);
				setVoucherData(voucherData.data);
			} catch (err) {
				console.log("Erro ao obter dados do cupom:", err);
			} finally {
				setLoading(false);
			}
		}
		getVoucherData();
		//eslint-disable-next-line
	}, [id]);

	return (
		<main className="voucherScreen">
			<TopMenu />
			{console.table(voucherData)}
			<section id="voucherCard">
				<div id="voucherCardPhotoArea">
					<img src={voucherData.logoPath} alt="" />
				</div>
				<div id="voucherCardOtherInfo">
					<h3>{voucherData.storeName}</h3>
					<p>
						<strong>Descrição Resumida: </strong>
						{" " + voucherData.description}
					</p>
					<p>
						<strong>Descrição Completa:</strong>
						{" " + voucherData.fullDescription}
					</p>
					<p>
						<strong>Validade:</strong>
						{" " + voucherData.expirationDate}
					</p>
					<p>{voucherData.status ? "Ativo" : "Inativo"}</p>
					<p>
						{voucherData.featured
							? "Em Destaque"
							: "Não está em destaque"}
					</p>
				</div>
				<div id="voucherActionsButtons">
					<h5>Ações</h5>
					<button>
						<MdDescription /> Alterar Descrição Resumida
					</button>
					<button>
						<MdDescription /> Alterar Descrição Completa
					</button>
					<button>
						<BsCalendar2DateFill /> Alterar validade
					</button>
					{voucherData.status ? (
						<button>
							<AiFillDislike />
							Inativar
						</button>
					) : (
						<button>
							<AiFillLike />
							Ativar
						</button>
					)}
					{voucherData.featured ? (
						<button>
							<MdRemoveCircle />
							Remover Destaque
						</button>
					) : (
						<button>
							<MdVerified />
							Ativar Destaque
						</button>
					)}
					<button>
						<RiDeleteBin5Fill /> Excluir
					</button>
				</div>
			</section>
			{}
			{loading && <LoadingSpinner />}
		</main>
	);
};
