import { AxiosWithAuthToken } from "../config";

export const getVoucherRanking = async (token) => {
	try {
		const response = await AxiosWithAuthToken(token).get(`/ranking`);
		return {
			statusCode: response.status,
			rankingList: response.data.result,
			total: response.data.resultTotal.totalUsedVouchers,
		};
	} catch (err) {
		throw err;
	}
};
