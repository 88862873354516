import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../api/Employees/login"; // Importe a função loginAdmin

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const navigate = useNavigate();
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const recoveredUser = localStorage.getItem("user");

		if (recoveredUser) {
			setUser(JSON.parse(recoveredUser));
		}

		setLoading(false);
	}, []);

	const login = async (email, password) => {
		try {
			const { statusCode, userData } = await loginAdmin(
				email,
				password
			);

			if (statusCode === 202) {
				const loggedUser = {
					id: userData.id,
					email: userData.email,
					name: userData.name,
					surname: userData.surname,
					token: userData.token,
				};

				localStorage.setItem(
					"user",
					JSON.stringify(loggedUser)
				);
				setUser(loggedUser);
				navigate("/home");
			} else {
				console.log("Usuário não é um administrador.");
				setUser(null);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const logout = () => {
		console.log("logout");
		localStorage.removeItem("user");
		setUser(null);
		navigate("/");
	};

	return (
		<AuthContext.Provider
			value={{
				authenticated: !!user,
				...user,
				loading,
				login,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
